import { IMAGE_FIELDS } from './snippets';

export const getProductAlertById = `//groq
    *[_type == "productAlert" 
    && !(_id in path("drafts.**")) 
    && dateTime(startsAt) < dateTime(now())
    && dateTime(expiresAt) > dateTime(now())
    && count((appliesTo[]->productId)[@  == $id]) > 0
    ][0]
    {
        "content": content[] {
            ...,
            _type == "image" => @ {
                ${IMAGE_FIELDS}
            },
            markDefs[] {
                ...,
                _type == "internalLink" => {
                    "slug": @.item->slug,
                }
            }
        }
    }
`;

export const getCategoryAlertById = `//groq
    *[_type == "productAlert" 
    && !(_id in path("drafts.**")) 
    && dateTime(startsAt) < dateTime(now())
    && dateTime(expiresAt) > dateTime(now())
    && count((appliesTo[]->_id)[@  == $id]) > 0
    ][0]
    {
      "content": content[] {
          ...,
          _type == "image" => @ {
              ${IMAGE_FIELDS}
          },
          markDefs[] {
              ...,
              _type == "internalLink" => {
                "slug": @.item->slug,
              }
          }
      }
    }
`;

const headerAlertFields = `//groq
  _id,
  "content": content[]{
    ...,
    markDefs[]{
      ...,
      _type == "internalLink" => {
        "slug": @.item->slug,
      }
    }
  },
`;

export const getHeaderAlertQuery = `//groq
{
  "banter": *[_type == "headerTextAlert" 
      && !(_id in path("drafts.**")) 
      && dateTime(startsAt) < dateTime(now()) 
      && (expiresAt == null || dateTime(expiresAt) > dateTime(now())) 
      && bulkBanterOnly == true 
      && $isBanter] | order(_updatedAt desc)
    {
      ${headerAlertFields}
    },
  "loggedIn": *[_type == "headerTextAlert" 
      && !(_id in path("drafts.**")) 
      && dateTime(startsAt) < dateTime(now()) 
      && (expiresAt == null || dateTime(expiresAt) > dateTime(now()))
      && loggedInOnly == true 
      && $isLoggedIn] | order(_updatedAt desc)
    {
      ${headerAlertFields}
    },
  "general": *[_type == "headerTextAlert" 
      && !(_id in path("drafts.**")) 
      && dateTime(startsAt) < dateTime(now()) 
      && (expiresAt == null || dateTime(expiresAt) > dateTime(now())) 
      && bulkBanterOnly != true
      && loggedInOnly != true] | order(_updatedAt desc)
    {
      ${headerAlertFields}
    }
}`;
