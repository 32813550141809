/* eslint-disable react-hooks/exhaustive-deps */
import debounce from 'lodash/debounce';
import React, { useEffect, useRef, useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { twJoin } from 'tailwind-merge';

import { Button } from '@components/Button';

export function ProductNav({
  links,
}: {
  links: { title: string; id: string }[];
}) {
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const productNavRef = useRef<HTMLDivElement>(null);

  const getMenuSize = () => {
    if (productNavRef.current) {
      return productNavRef.current.scrollWidth;
    }
    return 0;
  };

  const toggleRightArrow = () => {
    if (productNavRef.current) {
      const menuSize = getMenuSize();
      // size of the visible part of the menu is equal to the wrapper size
      const menuVisibleSize = productNavRef.current.offsetWidth;

      // if total size of the nav is not equal to the visible part of the nav, show the arrow
      // 10px is just to offset some border width and inconsistencies
      if (menuSize - menuVisibleSize >= 10) {
        setShowRightArrow(true);
      } else {
        // if total size of the nav is almost equal to the visible part of the nav, hide the arrow
        setShowRightArrow(false);
      }
    }
  };

  const scrollToPosition = (element: HTMLElement, pos: number) => {
    element.scrollTo({
      top: 0,
      left: pos,
      behavior: 'smooth',
    });
  };

  // This is messed up. Direction is only useful if clicked, else is a scroll event
  const handleNavButtonsShown = debounce((direction) => {
    if (productNavRef.current) {
      const wrapperEl = productNavRef.current;
      const menuSize = getMenuSize(); // get total width of all menu items
      const menuVisibleSize = wrapperEl.offsetWidth; // size of the visible part of the menu is equal to the wrapper size
      const menuInvisibleSize = menuSize - menuVisibleSize; // get how much of menu is invisible
      const menuPosition = wrapperEl.scrollLeft; // get how much have we scrolled so far
      const menuEndOffset = menuInvisibleSize - 24; // menu ends before the width of the arrows on both size (24px is the width of each arrow)

      // scroll an ammount equals to the size of the viewport either left or right
      if (direction === 'left') {
        scrollToPosition(wrapperEl, wrapperEl.scrollLeft - menuVisibleSize);
      }
      if (direction === 'right') {
        scrollToPosition(wrapperEl, wrapperEl.scrollLeft + menuVisibleSize);
      }

      // show & hide the arrows
      // depending on scroll position
      if (menuPosition <= 100) {
        setShowLeftArrow(false);
        setShowRightArrow(true);
      } else if (menuPosition < menuEndOffset) {
        setShowRightArrow(true);
        setShowLeftArrow(true);
      } else if (menuPosition >= menuEndOffset) {
        setShowLeftArrow(true);
        setShowRightArrow(false);
      }
    }
  }, 100);

  useEffect(() => {
    let wrapper;
    let mounted = true;

    if (mounted && productNavRef.current) {
      wrapper = productNavRef.current;
      wrapper.addEventListener('scroll', handleNavButtonsShown);
      toggleRightArrow();
    }

    return () => {
      mounted = false;
      if (wrapper) {
        wrapper.removeEventListener('scroll', handleNavButtonsShown);
      }
    };
  }, []);

  return (
    <div className={twJoin(['full-bleed', 'sticky top-0 z-1 min-h-[30px]'])}>
      <div
        ref={productNavRef}
        className={twJoin([
          'gae-product-nb horizontal-scroll',
          'overflow-x-auto whitespace-nowrap bg-grey-mid text-center text-sm dark:bg-grey-dark',
        ])}
      >
        {links.map((link, i) => (
          <Button
            scrollToId={link.id}
            key={`productnav-link-${i}`}
            buttonStyle="tertiary"
            className="xl:min-w-auto inline-block h-12 min-w-[100px] px-5 text-lg leading-none"
          >
            {link.title}
          </Button>
        ))}
      </div>
      {showLeftArrow && (
        <Button
          tabIndex={-1}
          className="absolute bottom-0 left-0 top-0 
          h-12 w-6 bg-grey-mid hover:bg-black
          hover:text-white"
          onClick={() => handleNavButtonsShown('left')}
          icon={<FiChevronLeft size="1.5rem" />}
        />
      )}
      {showRightArrow && (
        <Button
          tabIndex={-1}
          className="absolute bottom-0 right-0 top-0 
          h-12 w-6 bg-grey-mid hover:bg-black
          hover:text-white"
          onClick={() => handleNavButtonsShown('right')}
          icon={<FiChevronRight size="1.5rem" />}
        />
      )}
    </div>
  );
}
